/**
 * Type: コンポーネント
 * What: アコーディオン小
 */
import React, { useState } from 'react';
import * as Scroll from 'react-scroll';

const AccordionButton = ({title, targetId, children}) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
    if (!open) {
      Scroll.scroller.scrollTo(targetId, {
        duration: 1200,
        // delay: 60,
        offset: -127,
        smooth: 'easeInOutQuint',
      });
    }
  };

  return (
    <dl className={`acd ${open ? 'open' : ''}`} name={targetId}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="acd-parent acd-button"
        onClick={toggle}
      >
        <span>{title}</span>
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="16px">
            <path fillRule="evenodd" fill="rgb(8, 162, 242)" d="M-0.000,8.407 L-0.000,15.193 C-0.000,15.866 0.744,16.242 1.250,15.825 L9.532,9.010 C9.813,8.779 10.210,8.779 10.490,9.010 L18.748,15.804 C19.256,16.218 20.000,15.842 20.000,15.168 L20.000,8.384 C20.000,8.138 19.892,7.905 19.706,7.751 L10.490,0.172 C10.210,-0.058 9.813,-0.058 9.532,0.172 L0.294,7.776 C0.108,7.928 -0.000,8.162 -0.000,8.407 " />
          </svg>
        </div>
      </div>
      <div className="acd-child">
        {children && children}
      </div>
    </dl>
  );
};

export default AccordionButton;
